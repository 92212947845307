import React from 'react';
import virunthu from "./bhai-virunthu.jpeg";
import './BhaiVirunthu.css';

function BhaiVirunthu() {
  return (
    <div className="container">
      <div className="image-wrapper">
        <img src={virunthu} alt="food-festival" className="responsive-image" />
        <a href="https://api.whatsapp.com/send?phone=919790765344" target="_blank" rel="noopener noreferrer">
          <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" 
               alt="WhatsApp icon" 
               className="overlay-icon" />
        </a>
      </div>
    </div>
  );
}

export default BhaiVirunthu;