import BhaiVirunthu from "./BhaiVirunthu"

function App() {
  return (
    <>
       <BhaiVirunthu />
    </>
  );
}

export default App;